import React from 'react';

import { PAGES } from '../../utils/utils';
import Expertise from '../../components/en/expertise';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const ExpertisePage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES}>
    <SEO title="Expertise" lang="en" />
    <Expertise />
  </Layout>
);

export default ExpertisePage;
