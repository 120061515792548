import { Link } from 'gatsby';
import React, { useEffect } from 'react';

import { setupHaccordion } from '../../scripts/competences-haccordion';
import { getYearsSinceOfficeBirthFactorFive } from '../../utils/utils';

const Expertise = () => {
  useEffect(() => {
    setupHaccordion();
  });

  return (
    <>
      <div id="slogan" className="effect1">
        <h1>Expertise</h1>
      </div>

      <div id="img-wrap">
        <img
          src="/img/lemire-competences-large.jpg"
          alt="Expertise"
          title="Expertise"
        />
      </div>

      <div id="infoComp">
        <p id="infoCompP">
          <span className="accent2">Lemire Lemire avocats LLP</span> is proud to
          offer its customers a full range of services in several areas of law.
          Our responsible and individualized approach allows us to find the best
          solution for you in respect of your rights and needs.
        </p>

        <div id="hc1" className="haccordion">
          <ul>
            {/* Droit de la famille */}
            <li id="comp1" className="activeText">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img1">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Separation-Divorce</h2>
                          <p>
                            A separation or a divorce is often a major upheaval
                            in the lives of those involved and brings a lot of
                            questions.&nbsp;
                            <Link to="/en/expertise/separation-divorce">
                              Read More
                            </Link>{' '}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Garde d'enfants */}
            <li id="comp3">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img2">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Child Custody</h2>
                          <p>
                            Resolve differences concerning child custody and
                            parents' visiting rights.
                            <br />
                            <Link to="/en/expertise/child-custody">
                              Read More
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Médiation */}
            <li id="comp2">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img3">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Mediation</h2>
                          <p>
                            Family mediation can help you negotiate a settlement
                            in respect with the family members' interests.{' '}
                            <br />
                            <Link to="/competences/mediation">Read More</Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>

        <br />

        <div id="hc2" className="haccordion">
          <ul>
            {/* Responsabilité */}
            <li id="comp4">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img4">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Liability</h2>
                          <p>
                            We make every effort to search for the best outcome
                            for your injury compensation. &nbsp;
                            <Link to="/en/expertise/liability">Read More</Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Construction */}
            <li id="comp5">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img5">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Construction</h2>
                          <p>
                            We will help you with latent defects, legal
                            hypothecs and suretyship.
                            <br />
                            <Link to="/en/expertise/construction">
                              Read More
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Assurances */}
            <li id="comp6">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img6">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Insurance</h2>
                          <p>
                            We are qualified to resolve disputes related to
                            insurance claims. <br />
                            <Link to="/en/expertise/insurance">Read More</Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Immobilier */}
            <li id="comp7">
              <div className="hpanel">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img7">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text">
                          <h2>Real Estate</h2>
                          <p>
                            Our lawyers will work with you and inform you about
                            neighborhood annoyances, servitudes, positive
                            prescription and co-ownership. <br />
                            <Link to="/en/expertise/real-estate">
                              Read More
                            </Link>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>

        {/* ********************************************************************************* */}
        {/* Le contenu pour le responsive design se trouve en bas */}

        <div className="competencesDiv">
          <ul>
            {/* Droit de la famille */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img1b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Separation-Divorce</h2>
                          <p>
                            A <span className="accent">separation</span> or a{' '}
                            <span className="accent">divorce</span> is often a
                            major upheaval in the lives of those involved and
                            brings a lot of questions : What will happen about{' '}
                            <span className="accent2">child custody</span>? Who
                            will provide for their needs and to what amount?
                            What to do with the{' '}
                            <span className="accent2">family residence</span>?
                            Who will pay the family debts? What are my rights in
                            regard to <span className="accent2">support</span>?
                          </p>
                          <p>
                            Our experienced lawyers, among which one has more
                            than{' '}
                            <span className="accent">
                              {getYearsSinceOfficeBirthFactorFive()} years of
                              experience
                            </span>{' '}
                            in the field, will guide you through the conflict
                            you are facing and will work effectively with you to
                            find the best solutions for you with professionalism
                            in a listening environment.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Garde d'enfants */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img2b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Child Custody</h2>
                          <p>
                            Resolving differences concerning{' '}
                            <span className="accent">child custody</span> and
                            parents'{' '}
                            <span className="accent">visiting rights</span> has
                            a significant impact on the family. Our lawyers are{' '}
                            <span className="accent2">patient</span>,{' '}
                            <span className="accent2">diplomat</span> and{' '}
                            <span className="accent2">determined</span> when
                            solving these problems, and always keep as primary
                            objective the best interest of the child.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Médiation */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img3b"></div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Mediation</h2>
                          <p>
                            Family mediation can help you negotiate a{' '}
                            <span className="accent">settlement</span> in
                            respect with the family members' interests and
                            reduce your legal costs through a fixed number of
                            sessions subsidized by the Government of Quebec.
                          </p>
                          <p>
                            The consultation of an{' '}
                            <span className="accent2">
                              independent and qualified mediator
                            </span>{' '}
                            helps the parties to negotiate, with equal force,
                            the establishment of a post-separation agreement
                            respecting the distinct needs of each.
                          </p>
                          <p>
                            The parties already separated or divorced who want
                            to make a change to their agreement or judgment can
                            also do so through{' '}
                            <span className="accent2">negotiations</span> in
                            family mediation sessions.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Responsabilité */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img4b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Liability</h2>
                          <p>
                            Our lawyers handle a wide variety of{' '}
                            <span className="accent">bodily injury</span>{' '}
                            claims. <span className="accent2">Injuries</span>{' '}
                            often have a long term impact on the life quality of
                            our clients and that is why we make every effort to
                            search for the best outcome for your injury{' '}
                            <span className="accent2">compensation</span>.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Construction */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img5b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Construction</h2>
                          <p>
                            For more than{' '}
                            <span className="accent">
                              {getYearsSinceOfficeBirthFactorFive()} years
                            </span>
                            , our firm has represented all different players in
                            the field of construction :{' '}
                            <span className="accent2">
                              owners, contractors, subcontractors,
                            </span>{' '}
                            etc. Our experience touches all types of residential
                            and commerical construction projects. We will help
                            you with{' '}
                            <span className="accent">latent defects</span>,{' '}
                            <span className="accent">legal hypothecs</span> and{' '}
                            <span className="accent">suretyship</span>.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Assurances */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img6b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Insurance</h2>
                          <p>
                            We are qualified to resolve disputes related to{' '}
                            <span className="accent">insurance claims</span>{' '}
                            such as{' '}
                            <span className="accent">
                              auto insurance, home insurance, disability
                              insurance
                            </span>{' '}
                            and <span className="accent">life insurance</span>.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>

            {/* Immobilier */}
            <li>
              <div className="competencesTable">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="image" id="img7b">
                          <span className="hacc-hover"></span>
                        </div>
                      </td>
                      <td>
                        <div className="text480">
                          <h2>Real Estate</h2>
                          <p>
                            Disagreements may arise after the acquisition of a
                            home, be it requests for cancellation of the sale or
                            reduction of the sale price, neighborhood
                            annoyances, land limits and common ownership issues,
                            etc.
                          </p>
                          <p>
                            In all these cases, our services will prove to be
                            useful to defend your rights in the most efficient
                            way.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
        {/* Close the section for responsive design */}
      </div>
    </>
  );
};

Expertise.propTypes = {};

export default Expertise;
